.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.loginbg{
    background: url("./assets/loginbg1Lor.jpg"); 
      min-height: 75vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
     
  }
  .blur-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Adjust the alpha value for opacity */
    backdrop-filter: blur(10px); /* Adjust the blur radius */
}
.upload-bg{
  background: url('./assets/uploadLor.jpg');
   min-height: 75vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
}
.reset-bg{
  background: url('./assets/resetBG.jpg');
   min-height: 75vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
}
  
  @media (max-width: 500px){
    .loginbg{
      background: url("./assets/slogin.jpg"); 
      min-height: 70vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .upload-bg{
      background: url("./assets/loginbglibrarysmall.jpg"); 
      min-height: 70vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .reset-bg{
  background: url('./assets/resetBG.jpg');
  min-height: 70vh;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      justify-content: center;
      align-items: center;
}
  }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
